.find__section {
  padding-bottom: 50px;

  @media (min-width: 768px) {
    padding-top: 0;
  }
}

.site_socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  align-items: center;
  row-gap: 20px;

  @media (min-width: 500px) {
    justify-content: space-evenly;
    gap: 30px;
  }

  @media (min-width: 768px) {
    gap: 50px;
  }
}

.site_socials a {
  display: flex;
  background: #e3edf734;
  height: 60px;
  width: 60px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  transition: transform 0.5s;

  @media (min-width: 500px) {
    height: 80px;
    width: 80px;
  }

  @media (min-width: 768px) {
    height: 110px;
    width: 110px;
  }
}

.site_socials a:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.01);
  transform: translateY(2px);
}

.site_socials svg {
  width: 30px;
  height: 30px;
  fill: #777;

  @media (min-width: 500px) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .site_socials a.icon-1 svg {
    fill: var(--main-color);
  }

  .site_socials a.icon-2 svg {
    fill: #0e76a8;
  }

  .site_socials a.icon-3 svg {
    fill: var(--text-color);
  }

  .site_socials a.icon-4 svg {
    fill: #0085ff;
  }

  .site_socials a.icon-5 svg {
    fill: rgb(190, 21, 21);
  }
}

@media (min-width: 500px) {
  .site_socials a.icon-1:hover svg {
    fill: var(--main-color);
  }

  .site_socials a.icon-2:hover svg {
    fill: #0e76a8;
  }

  .site_socials a.icon-3:hover svg {
    fill: var(--text-color);
  }

  .site_socials a.icon-4:hover svg {
    fill: #0085ff;
  }

  .site_socials a.icon-5:hover svg {
    fill: rgb(190, 21, 21);
  }

  .site_socials a.icon:hover:after {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--main-color);

    @media (min-width:500px) {
      bottom: -35px;
    }

    @media (min-width:768px) {
      bottom: -50px;
    }
  }

  .site_socials a.icon-1:hover:after {
    content: "Send Email";
    width: 110px;
    text-align: center;
  }

  .site_socials a.icon-2:hover:after {
    content: "LinkedIn";

  }

  .site_socials a.icon-3:hover:after {
    content: "Github";
  }

  .site_socials a.icon-4:hover:after {
    content: "Bluesky";
  }

  .site_socials a.icon-5:hover:after {
    content: "Google Map";
    width: 110px;
    text-align: center;
  }
}

#bg-image {
  position: relative;
  height: 70vh; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/assets/images/aaron-kate-ellen-val-defense-169-2mb.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 0;
  @media (min-width: 768px) {
    background-position: center center;
    height: 85vh; 
  }
}

#bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(33, 40, 41, 0.8), rgba(29, 33, 34, 0.5));
  z-index: 5;
}

