.positions__section {
  background-color: var(--text-color);
  color: white;
}

.positions__row {
  display: grid;
  gap: 10px;

  @media (min-width: 900px) {
    display: flex;
    gap: 150px;
  }
}

.positions__row h2 {
  max-width: 650px;
  width: 100%;

}

.positions__text a {
  color: var(--link-color);
  &:hover{
    opacity: 0.8;
  }
}