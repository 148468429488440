.page__error {
  text-align: center;
  svg {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;

    @media (min-width: 600px) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .btn {
    text-align: center;
    margin-top: 30px;
  }
}