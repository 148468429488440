/* Raleway - regular */
@font-face {
  font-family: 'Raleway', sans-serif;
  src: url(/assets/fonts/Merriweather/Merriweather-Black.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Raleway - medium*/
@font-face {
  font-family: 'Raleway', sans-serif;
  src: url(/assets/fonts/Raleway/Raleway-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Raleway - bold */
@font-face {
  font-family: 'Raleway', sans-serif;
  src: url(/assets/fonts/Raleway/Raleway-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Raleway - black */
@font-face {
  font-family: 'Raleway', sans-serif;
  src: url(/assets/fonts/Raleway/Raleway-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

h1 {
  color: var(--h1-color);
  font-size: clamp(2rem, calc(10vmin + 5px), 3rem);
  line-height: clamp(2.4rem, calc(10vmin + 5px), 3.1rem);

}

h2 {
  color: var(--text-color);
  font-size: 30px;
  margin-bottom: 30px;
  z-index: 10;
  line-height: 0.9;
  word-break: break-word;

  @media (min-width: 600px) {
    margin-bottom: 50px;
    font-size: 40px;
  }

  &.border {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--text-color)
  }
}

h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;

  @media (min-width: 600px) {
    font-size: 30px;
  }
}

p {
  padding-bottom: 10px;
}

a {
  color: var(--main-color);
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: var(--link-color);
  }
}