.header {
  height: 74px;
}

.header__nav {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2rem 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @media (min-width: 768px) {
    padding: 40px 0;
    gap: 30px;
  }
}

.header__nav a {
  font-size: 0.8rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  text-transform: lowercase;

  @media (min-width: 1024px) {
    padding-right: 20px;
    color: var(--text-color);
  }

  &:last-of-type {
    padding-right: 0;
  }

  &:hover {
    color: var(--main-color);
    font-weight: 500;
  }
}

a .logo-img {
  display: inline-block;
  width: 40px;
  object-fit: inherit;
  padding: 0 5px;
  width: 50px;

  @media (min-width: 768px) {
    width: 60px;
  }
}



nav {
  display: inline-block;
  /*position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background-color: var(--text-color);
  transition: right 0.5s ease-in;
  overflow: hidden;
  margin: auto;
  padding: 150px 0 0;
  z-index: 20;

  &.active {
    display: block;
    transform: translateX(0);
  }*/

  @media (min-width: 1024px) {
    overflow: inherit;
    position: inherit;
    transform: translateX(0);
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}

.nav-links {
  display: flex;
  justify-content: end;
  //flex-direction: column;
  align-items: center;
  gap: 15px;

  @media (min-width: 500px) {
    top: 84px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }

}

.burger {
  display: none;
  z-index: 10;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  z-index: 25;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  justify-self: end;

  @media (min-width: 1024px) {
    display: none;
  }

  &:active {
    display: block;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: 1024px) {
      display: none;
    }
  }
}


.burger span {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--text-color);
  border-radius: 2px;
  transition: all 0.5s;
}

.burger span:nth-of-type(2) {
  top: calc(50% - 7px);
}

.burger span:nth-of-type(3) {
  top: calc(50% + 7px);
}

.burger.active span:nth-of-type(1) {
  display: none;
}

.burger.active span:nth-of-type(2) {
  background-color: white;
  top: 50%;
  transform: translate(-50%, 0%) rotate(45deg);
}

.burger.active span:nth-of-type(3) {
  background-color: white;
  top: 50%;
  transform: translate(-50%, 0%) rotate(-45deg);
}