.hero {
  margin: 80px 0 100px;

  @media (min-width: 768px) {
    margin: 110px 0 150px;
  }

  .hero__text {
    max-width: 1200px;
  }
}
