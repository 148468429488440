#GoTop {
  border: none;
  outline: 0;
  background: 0 0;
  position: fixed;
  bottom: 90px;
  right: 16px;
  font-weight: 700;
  color: rgb(155, 152, 152);
  padding: 0 10px 0 8px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.6;
  z-index: 35;

  span {
    display: inline-block;
    font-family: "neo latina";
    font-weight: 400;
    font-size: 40px;
    transform: rotate(-90deg);

    @media (min-width: 768px) {
      font-size: 40px;
    }
  }

  &:hover {
    background-color: transparent;
    color: var(--h1-color);
    opacity: 0.8;
  }

  @media (min-width: 768px) {
    right: 40px;
    bottom: 20px;
    padding: 0 10px;
  }
}